<template>
  <div>

    <div class="plugin-list-title">
      <span>
          <a-divider type="vertical" style="height: 60px; background-color: #7cb305" /> 插件市场
      </span>
      <a-input-search
          v-model:value="value"
          placeholder="搜索插件和应用，拓展无限可能"
          size="large"
          style="width: 50%;float: right;margin-top: 10px"
          @search="onSearch"
      />

    </div>

    <a-divider />

    <a-list item-layout="vertical" size="large" :pagination="pagination" :data-source="listData.list">
      <template #footer>
        <div>
          <!--          <b>ant design vue</b>-->
          <!--          footer part-->
          没找到想要的插件？点这里提需求；想推广你的插件？点这里 {{ value }}
        </div>
      </template>
      <template #renderItem="{ item }">
        <a-list-item key="item.title">


          <template #extra>
            <router-link :to="'/plugins/particular/' + item.pluginCode">
              <img
                  width="100"
                  alt="logo"
                  :src="item.icon"
              />
            </router-link>
          </template>


          <template #actions>
            <span v-for="{ type, text } in actions" :key="type">
              <component :is="type" style="margin-right: 8px"/>
              {{ text }}
            </span>
          </template>

          <router-link :to="'/plugins/particular/' + item.pluginCode">
            <a-list-item-meta :description="item.deptName">
              <template #title>
                <a :href="item.href">{{ item.pluginName }}</a>
              </template>
              <template #avatar>
                <a-avatar :src="item.icon"/>
              </template>
            </a-list-item-meta>
          </router-link>

          {{ item.remarks }}

        </a-list-item>
      </template>
    </a-list>

  </div>
</template>

<script>
import {onMounted, reactive, ref} from 'vue';

import {LikeOutlined, MessageOutlined, StarOutlined} from '@ant-design/icons-vue';
import {getPluginsHub} from "@/api/plugins";


export default {
  name: "PluginList",
  components: {
    StarOutlined,
    LikeOutlined,
    MessageOutlined,
  },
  setup() {
    //列表数据
    const pagination = {
      onChange: page => {
        console.log(page);
      },
      pageSize: 20,
    };
    const actions = [
      {
        text: 'JSON',
      }, {
        text: 'HoHux',
      },
      {
        type: 'StarOutlined',
        text: '156',
      },
    ];

    // 搜索相关数据
    let value = ref('');

    let searchForm = {};
    let pageData = reactive({
      list: []
    });

    const onSearch = searchValue => {
      searchForm.pluginName = searchValue;
      getData();
    };

    const getData = () => {
      getPluginsHub(searchForm).then((response) => {
        pageData.list = response.data.records;
      })
    }

    onMounted(() => {
      getData();
    })

    return {
      //列表数据
      listData: pageData,
      pagination,
      actions,

      // 搜索相关数据
      value,
      onSearch,
    };
  },
}
</script>

<style scoped>
.plugin-list-title {
  font-size: 20px;
  font-weight: 600;
  height: 60px;
  line-height: 60px;
  margin-bottom: 16px;
  margin-top: 20px;
}
</style>
