<template>
  <div>
    <div>
      <img src="../../assets/img/plugin/插件市场.png">
    </div>

    <a-row>
      <a-col :span="18" :offset="3">
        <a-layout class="plugin-hub">

          <!--          <a-layout-sider>-->
          <!--            <PluginMenu/>-->
          <!--          </a-layout-sider>-->

          <a-layout-content>
            <PluginList/>
          </a-layout-content>

        </a-layout>
      </a-col>
    </a-row>

    <!--    <a-row>-->
    <!--      <a-col :span="4">-->
    <!--        <PluginMenu/>-->
    <!--      </a-col>-->
    <!--      <a-col :span="20">col-12</a-col>-->
    <!--    </a-row>-->
  </div>
</template>

<script>
import PluginList from "@/components/plugin/List";
import {ref} from "vue";

export default {
  name: "Index",
  components: {PluginList},
  setup() {
    const onChange = (current) => {
      console.log(current);
    };

    const value = ref('');

    return {
      value,
      onChange,
    };
  },
};
</script>

<style scoped>

.plugin-hub {
  margin-top: 20px;
  background-color: #ffffff;
  padding: 20px;
}

img {
  width: 100%;
}
</style>
